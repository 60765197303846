.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

#docs-content {
  width: 90vw;
  padding: 30px;
  overflow-y: scroll;
  background: #f3f4f5;
}

#docs-content > .swagger-ui {
  background: #fff;
  border-radius: 5px;
  padding: 16px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

#docs-content > .swagger-ui .scheme-container {
  background: #fff;
  box-shadow: none;
}

#docs-content .asyncapi__table-cell:first-child {
    white-space: nowrap;
}
