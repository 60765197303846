.side-bar {
  display: flex;
  flex-direction: column;
  width: 15vw;
  height: 100vh;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1), 0 2px 5px rgba(0,0,0,0.2);
}

.side-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: whitesmoke;
  background-color: #2d383a;
  border-bottom: 5px solid #84cc32;
}

.side-bar-header h1 {
  margin: 0;
  font-size: 30pt;
}

.side-bar-body {
  padding-left: 10px;
}