.documentation-link {
  margin-top: 10px;
  font-size: 12pt;
  transition: all .1s ease;
  padding-left: 10px;
}

.documentation-link:hover {
  cursor: pointer;
  border-left: 5px solid #84cc32;
  transition: all .1s ease;
}