@font-face {
  font-family: 'SAP-icons';
  src: url('../../assets/fonts/SAP/SAP-icons.woff2') format('woff2'),
    url('../../assets/fonts/SAP/SAP-icons.woff') format('woff'),
    url('../../assets/fonts/SAP/SAP-icons.ttf') format('truetype'), local('SAP-icons');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: '72';
  font-style: normal;
  font-weight: 400;
  src: local('72'), url('../../assets/fonts/72/72-Regular.woff2') format('woff2'),
    url('../../assets/fonts/72/72-Regular.woff') format('woff');
}

@font-face {
  font-family: '72';
  font-style: normal;
  font-weight: 700;
  src: local('72-Bold'), url('../../assets/fonts/72/72-Bold.woff2') format('woff2'),
    url('../../assets/fonts/72/72-Bold.woff') format('woff');
}